import React, {Component, useRef} from 'react';
import Input from '../../components/UI/Input/FormInput';
import {Col, Row, Button, FormGroup, FormText, Progress, Input as InputUncontrolled, Label} from 'reactstrap';
import axios from '../../axios-base';
import PhoneNumber from 'awesome-phonenumber';
import SMSVotingProcessStepZero from '../../components/UI/ReCarousel/CarouselAssets/SMSVotingProcessStepZero';
import SMSVotingProcessStepOne from '../../components/UI/ReCarousel/CarouselAssets/SMSVotingProcessStepOneMobile';
import SMSVotingProcessStepTwo from '../../components/UI/ReCarousel/CarouselAssets/SMSVotingProcessStepTwoMobile';
import SMSVotingProcessStepThree from '../../components/UI/ReCarousel/CarouselAssets/SMSVotingProcessStepThree';
import SMSVotingProcessStepOneDesktop
    from '../../components/UI/ReCarousel/CarouselAssets/SMSVotingProcessStepOneDesktop';
import SMSVotingProcessStepThreeDesktop
    from '../../components/UI/ReCarousel/CarouselAssets/SMSVotingProcessStepThreeDesktop';
import ReCarousel from "../../components/UI/ReCarousel/ReCarousel";
import Cookies from "js-cookie";
import moment from 'moment';
import ReCaptcha from 'reaptcha';
import {Helmet} from "react-helmet";
import {sendVirtualPageView} from "../../shared/utility";
import Modal from '../../components/UI/Modal/Modal';

class SMSRequest extends Component {
    constructor(props) {
        super(props);
        this.captcha = null;
        this.captchaRef = React.createRef();
        const windowSize = window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
        const renderCarousel = windowSize < 575;

        this.state = {
            userInput: '',
            pn: '',
            touched: false,
            formIsValid: false,
            desiredSlide: 0,
            renderCarousel,
            windowSize,
            SVGOne: <SMSVotingProcessStepOne
                afterInjection={(e) => this.setState({flexibleHeight: this.calculateFlexibleHeight(e)})}/>,
            SVGTwo: <SMSVotingProcessStepTwo/>,
            SVGThree: <SMSVotingProcessStepThree/>,
            SVGOneDesktop: <SMSVotingProcessStepOneDesktop/>,
            SVGThreeDesktop: <SMSVotingProcessStepThreeDesktop/>,
            submitSuccess: false,
            progress: 0,
            flexibleHeight: 0,
            incorrectPuzzleCount: 0,
            correctPuzzleCount: 0,
            showModal: false,
            unsuccessfulMessage: '',
            displayCaptchaContainer: true
        }

        this.navigationHandler = this.navigationHandler.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onClickHandler = this.onClickHandler.bind(this);
        this.startCountdown = this.startCountdown.bind(this);
        this.calculateFlexibleHeight = this.calculateFlexibleHeight.bind(this);
    }

    timer;

    componentDidMount() {
        window.addEventListener("resize", this.onResizeHandler);
        sendVirtualPageView("Jetzt Abstimm-Code anfordern")
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResizeHandler);
        clearTimeout(this.timer);
    }

    navigationHandler() {
        this.clickChild(this.state.desiredSlide);
    };


    onChangeHandler = event => {
        let userInput = event.target.value;
        let pn = PhoneNumber(userInput, 'DE');
        let formattedOutput = pn.getNumber('national');

        this.setState({
            userInput: userInput,
            pn: formattedOutput,
            touched: true,
            formIsValid: pn.isMobile() && pn.isValid()
        }, () => {
            if (this.state.renderCarousel) {
                if (this.state.formIsValid) {
                    this.setState({desiredSlide: 1}, () => {
                        this.navigationHandler();
                    })
                } else {
                    this.setState({desiredSlide: 0}, () => {
                        this.navigationHandler();
                    })
                }
            } else {
                if (this.state.formIsValid) {
                    this.setState({desiredSlide: 1})
                } else {
                    this.setState({desiredSlide: 0})
                }
            }
        });
    }

    onVoteHandler = () => {
        // this.captcha.execute();
        console.log("on Vote handler called ...")
        this.requestSMS()
    };

    sizes = () => {
        let contentWidth = [...document.body.children].reduce(
                (a, el) => Math.max(a, el.getBoundingClientRect().right), 0)
            - document.body.getBoundingClientRect().x;

        return {
            windowWidth: document.documentElement.clientWidth,
            windowHeight: document.documentElement.clientHeight,
            pageWidth: Math.min(document.body.scrollWidth, contentWidth),
            pageHeight: document.body.scrollHeight,
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            pageX: document.body.getBoundingClientRect().x,
            pageY: document.body.getBoundingClientRect().y,
            screenX: -window.screenX,
            screenY: -window.screenY - (window.outerHeight - window.innerHeight),
        }
    }

    onResizeHandler = () => {
        let renderCarousel;
        let windowSize = window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth

        renderCarousel = windowSize < 575;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({
                windowSize: windowSize,
                renderCarousel: renderCarousel
            });
        }, 500)
    }

    onClickHandler = () => {
        if (Cookies.get('bigfoot_redirect-category')) {
            this.props.history.push(`/projekte/?category=${Cookies.get('bigfoot_redirect-category')}`)
        } else {
            this.props.history.push('/projekte/');
        }
    }

    startCountdown = () => {
        if (this.state.progress === 0) {
            this.countdowner = setInterval(this.countdown, 1000)
        }
    }

    countdown = () => {
        let progress = this.state.progress + 10;
        this.setState({
            progress: progress
        }, () => {
            if (this.state.progress > 99) {
                clearInterval(this.countdowner);
                if (Cookies.get('bigfoot_redirect-category')) {
                    this.props.history.push(`/projekte/?category=${Cookies.get('bigfoot_redirect-category')}`)
                } else {
                    this.props.history.push('/projekte/');
                }
            }
        })
    };

    onError = () => {
        console.log('Es ist ein Fehler beim Verifizieren des reCAPTCHAS aufgetreten!');
    };

    onLoad = () => {
        console.log('reCAPTCHA wird initialisiert...')
    }


    calculateFlexibleHeight(svg) {
        const assetWidth = svg.viewBox.baseVal.width;
        const assetHeight = svg.viewBox.baseVal.height;
        return ((assetHeight / assetWidth) * this.state.windowSize) - 15;
    }

    showMyCaptcha = () => {
        console.log("show my captcha called .... API KEY", process.env.REACT_APP_AWS_WAF_CAPTCHA_KEY)
        const container = document.querySelector("#my-captcha-container");
        window.AwsWafCaptcha.renderCaptcha(container, {
            apiKey: process.env.REACT_APP_AWS_WAF_CAPTCHA_KEY,
            onSuccess: this.onCaptchaVerified,
            onError: this.captchaExampleErrorFunction,
            dynamicWidth: true,
            defaultLocale: "de-DE",
            onPuzzleIncorrect: this.captchaExampleIncorrectFunction,
            onPuzzleCorrect: this.captchaExampleCorrectFunction
            // Other configuration parameters as needed
        });
    }

    onCaptchaVerified = () => {
        this.setState({displayCaptchaContainer: false}, () => {
            this.onVoteHandler();

        });
    }

    checkCaptchaContent = () => {
        const captchaContainer = this.captchaRef.current;

        if (captchaContainer && captchaContainer.innerHTML.trim() === '') {
            console.log('Captcha container is empty');
            // Do something when it's empty
        } else {
            console.log('Captcha container is not empty');
            console.log("captcha: ", captchaContainer)
            console.log("title element  :", document.querySelector(".amzn-captcha-modal-title"))
            document.querySelector(".amzn-captcha-modal-title").innerHTML = 'hello'
            // Do something when it's not empty
        }
    };


    requestSMS() {
        console.log("REQUEST SMS called: pn in state : ", this.state.pn)
        // window.awsWafCookieDomainList = ['.arm.projectdraco.de', '.projectdraco.de']
        window.AwsWafIntegration.getToken().catch(e => {
            console.log("couldnt get the token", e);
        }).then(token => {
            console.log("token value before success: ", token)
            window.AwsWafIntegration.fetch(`${process.env.REACT_APP_API_URL}/sms`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-aws-waf-token': token,
                },
                body: JSON.stringify({recipient: this.state.pn})
            }).then(response => {
                    console.log("Response from SMS request: ", response);
                    if (response.status === 405){
                        console.log("Client captcha should render... ")
                        this.showMyCaptcha()
                        // this.checkCaptchaContent();

                    } else if (response.status === 201){
                        console.log("Successful Request... ")
                        this.handleSuccessfulRequest();
                    } else {
                        console.log("UNNSuccessful Request... ")
                        this.handleUnsuccessfulSMSRequest(response);
                    }
                }).catch(err => {
                console.log("sms Request came back with an error : ", err);
                this.handleUnsuccessfulSMSRequest(err);
            })
        })
    }

    handleSuccessfulRequest = () => {
        console.log("handle success request called ")
        //redirect code comes here ...
        if (this.state.renderCarousel) {
            this.setState({
                desiredSlide: 2,
                submitSuccess: true
            }, () => {
                this.navigationHandler();
            })
        } else {
            this.setState({
                desiredSlide: 2,
                submitSuccess: true
            })
        }
        if (this.state.formIsValid) {
            this.startCountdown()
        }
        if (!Cookies.get('bigfoot_code-requested')) {
            Cookies.set('bigfoot_code-requested', true, {expires: 7})
        }
    }

    handleUnsuccessfulSMSRequest = (responseOrError) => {
        console.log("handle UNsuccess request called ");

        // Check if it's a fetch response or an error object
        if (responseOrError instanceof Response) {
            // Handle fetch Response object
            responseOrError.text().then((responseText) => {
                console.log("Response Text ", responseText);
                this.setState({
                    showModal: true,
                    unsuccessfulMessage: responseText
                }, () => {
                    console.log("modal should show now...");
                });
            }).catch(err => {
                console.log("Error parsing response text: ", err);
                this.setState({
                    showModal: true,
                    unsuccessfulMessage: "An error occurred, but we couldn't parse the response."
                });
            });
        } else {
            // Handle error object (e.g., network error)
            console.log("Handling error object: ", responseOrError);
            this.setState({
                showModal: true,
                unsuccessfulMessage: "An unexpected error occurred. Please try again later."
            });
        }
    }


    captchaExampleSuccessFunction = () => {
        console.log("captcha example success function called ...")
        window.AwsWafIntegration.fetch(`${process.env.REACT_APP_API_URL}/sms`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                // 'x-aws-waf-token': token,
            },
            body: JSON.stringify({recipient: this.state.pn})
            // body: {recipient: this.state.pn}
        }).then(response => {
            console.log("Response from SMS request: ", response);
            // console.log(response.text());
            // console.log(response.json());
            if (response.status === 201){
               //redirect code comes here ...
                if (this.state.renderCarousel) {
                    this.setState({
                        desiredSlide: 2,
                        submitSuccess: true
                    }, () => {
                        this.navigationHandler();
                    })
                } else {
                    this.setState({
                        desiredSlide: 2,
                        submitSuccess: true
                    })
                }
                if (this.state.formIsValid) {
                    this.startCountdown()
                }
                if (!Cookies.get('bigfoot_code-requested')) {
                    Cookies.set('bigfoot_code-requested', true, {expires: 7})
                }
            } else {
                // show modal with error ...
                //prevent redirect ...
                response.text.then((responseText) => {
                    console.log("Response Text ", responseText)
                    this.setState({
                        showModal: true,
                        unsuccessfulMessage: responseText
                    }, () => {
                        console.log("modal should show now...")
                    })
                }).catch(err => {
                    console.log("cannot parse !")
                })

            }
        }).catch(err => {
            console.log("sms Request came back with an error : ", err);
        })
    }
    captchaExampleErrorFunction = (error) => {
        // Handle captcha error
        console.error('Captcha error:', error);
        // Do something with the error
    }
    captchaExampleIncorrectFunction = () => {
        console.log("incorrect puzzle registered")
        this.setState(prevState => {
            return {
                ...prevState, incorrectPuzzleCount: prevState.incorrectPuzzleCount + 1
            }
        },(() => {
            console.log("-- incorrect puzzle count: ", this.state.incorrectPuzzleCount)

        }))
    }

    captchaExampleCorrectFunction = () => {
        console.log("correct puzzle registered")
        this.setState(prevState => {
            return {
                ...prevState, correctPuzzleCount: prevState.correctPuzzleCount + 1
            }
        },(() => {
            console.log("-- correct puzzle count: ", this.state.correctPuzzleCount)
        }))
    }

    closeModalHandler = () => {
        this.setState({ showModal: false });
    };

    render() {
        let SMSVotingProcess;
        let buttonText = 'Bitte gültige Mobilnummer angeben';
        let form;
        // @Todo: Refactor dynamic form into its own component
        let DynamicForm = ({children}) => {
            return <React.Fragment>
                <FormGroup className="mb-2">
                    <Label for="voteEnded">Ihre Mobilnummer</Label>
                    <InputUncontrolled id="voteEnded" disabled className="mb-2" placeholder="0123 4567890"/>
                </FormGroup>
                <Row>
                    <Col>
                        {children}
                    </Col>
                </Row>
            </React.Fragment>
        };
        const ImageSetMobile = [this.state.SVGOne, this.state.SVGTwo, this.state.SVGThree];
        const ImageSetDesktop = [this.state.SVGOneDesktop, this.state.SVGTwo, this.state.SVGThreeDesktop];

        if (this.state.renderCarousel) {
            SMSVotingProcess = (
                <Col>
                    {/*flexible height is calculated after image load*/}
                    <div style={{height: this.state.flexibleHeight, userSelect: "none"}}>
                        <ReCarousel axis={"x"} className="re-carousel" setClick={click => this.clickChild = click}
                                    desiredSlide={this.state.desiredSlide} onStartFrameIndex={this.state.desiredSlide}>
                            {ImageSetMobile.map((image, index) => (
                                <div style={index === this.state.desiredSlide ? {transition: '1s'} : {
                                    filter: 'grayscale(1)',
                                    transition: '1s'
                                }}
                                     key={index}>
                                    {image}
                                </div>
                            ))}
                        </ReCarousel>
                    </div>
                </Col>
            );
        } else {
            SMSVotingProcess = ImageSetDesktop.map((image, index) => {
                return (
                    <Col style={index === this.state.desiredSlide ? {transition: '100ms'} : {
                        filter: 'grayscale(1)',
                        transition: '100ms'
                    }}
                         key={index}>
                        {image}
                    </Col>
                )
            });
        }

        if (this.state.formIsValid) {
            buttonText = 'Jetzt Code anfordern'
            if (this.state.submitSuccess) {
                buttonText = 'Sie werden weitergeleitet...'
            }
        }

        const MultiButton = ({children}) => {
            if (!this.state.submitSuccess) {
                return <Button
                    block
                    className="position-relative p-0 w-100 anl_sms--request"
                    disabled={!this.state.formIsValid}
                    color="primary"
                    onClick={this.onVoteHandler}>
                    {children}
                </Button>
            } else {
                return <span
                    className="d-block btn btn-primary p-0 w-100 anl_sms--skip-redirect"
                    onClick={this.onClickHandler}>
                        {children}
                </span>
            }
        }

        // phone no. entry field configuration
        const PHONENUMBERCONFIG = {
            elementType: "input",
            elementConfig: {
                type: "text",
                inputMode: "numeric",
                pattern: "[0-9 ]+",
                placeholder: "0123 4567890",
                label: "Ihre Mobilnummer",
                columns: 12,
                disabled: this.state.submitSuccess
            },
            validation: {
                required: {value: true, errorMessage: "Bitte geben Sie eine gültige Mobilnummer an"},
                maxLength: {value: 11, errorMessage: "Bitte geben Sie eine gültige Mobilnummer an"}, // @Todo: correct value for min and max length of mobile phone numbers (in Germany)
                minLength: {value: 10, errorMessage: "Bitte geben Sie eine gültige Mobilnummer an"}
            },
            errorMessage: 'Bitte geben Sie eine gültige Mobilnummer an',
            ifAuth: false
        }

        // Finale has started and contest has not yet ended
        if (!this.props.votingPhases.voteStartedFinale) {
            form = (
                <DynamicForm>
                    <Button block disabled color="primary"
                            style={{cursor: 'not-allowed'}}>Die Abstimmung startet
                        am {moment(this.props.votingPhases.voteStartFinale).format('DD.MM.')}</Button>
                </DynamicForm>
            );
        } else if (this.props.votingPhases.voteStartedFinale && !this.props.votingPhases.voteEnded) {
            form = (
                <React.Fragment>
                    <FormGroup row className="mb-0">
                        <Input
                            autoFocus={true}
                            name="mobilePhoneNumber"
                            elementType={PHONENUMBERCONFIG.elementType}
                            elementConfig={PHONENUMBERCONFIG.elementConfig}
                            value={this.state.formIsValid ? this.state.pn : this.state.userInput}
                            changed={(event) => this.onChangeHandler(event)}
                            shouldValidate={PHONENUMBERCONFIG.validation}
                            touched={this.state.touched}
                            errorMessage={PHONENUMBERCONFIG.errorMessage}
                            invalid={!this.state.formIsValid}
                            formText={PHONENUMBERCONFIG.formText}
                            wrapperCSSClass="mb-2"
                        />
                    </FormGroup>
                    <div className={`w-100 ${this.state.displayCaptchaContainer ? "" : "d-none" }`} ref={this.captchaRef} id="my-captcha-container"/>
                    <div className="w-100" id="captcha-container"/>
                    <div className="w-100" id="my-output-box" />
                    <Row>
                        <Col>
                            <MultiButton>
                                <Progress style={{height: '2rem'}}
                                          className={`position-relative ${this.state.submitSuccess ? "bg-dark" : "bg-primary"}`}
                                          color="primary" value={this.state.progress}>
                                    <span className="position-absolute"
                                          style={{left: 0, right: 0, fontSize: '1rem'}}>{buttonText}</span>
                                </Progress>
                            </MultiButton>
                            <FormText>Einmaliger SMS-Versand an deutsche Mobil&shy;funk&shy;nummern. Diese Seite
                                wird durch reCAPTCHA geschützt.
                                Es gelten die <a className="anl_recaptcha--privacy" target="_blank"
                                                 rel="noopener noreferrer"
                                                 href="https://policies.google.com/privacy">Daten&shy;schutz&shy;erklärung</a> und
                                die <a className="anl_recaptcha--terms" target="_blank" rel="noopener noreferrer"
                                       href="https://policies.google.com/terms">Nutzungs&shy;bedingungen</a> von
                                Google.</FormText>
                        </Col>
                    </Row>
                </React.Fragment>
            )
        } else {
            form = (
                <DynamicForm>
                    <Button block disabled color="primary"
                            style={{cursor: 'not-allowed'}}>Der Wettbewerb ist beendet</Button>
                </DynamicForm>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`${process.env.REACT_APP_PROJECT_TITLE}: Jetzt Abstimm-Code anfordern`}</title>
                </Helmet>
                <Row className="mt-3">
                    <Col>
                        <h2>Eine Stimme abgeben</h2>
                        <p>Ihrem Favoriten eine Stimme geben!</p>
                    </Col>
                </Row>
                <Row>
                    {SMSVotingProcess}
                </Row>
                <Row className="my-1">
                    <Col className="col-12 col-lg-4 col-md-5">
                        <ReCaptcha
                            ref={e => (this.captcha = e)}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                            onVerify={this.onVerify}
                            onError={this.onError}
                            onLoad={this.onLoad}
                            size="invisible"
                        />
                        {form}
                    </Col>
                    <Col className="col-12 col-lg-8 col-md-7 mt-3">
                        <h2>So stimmen Sie ab:</h2>
                        <ul>
                            <li>Geben Sie Ihre Handynummer ein.</li>
                            <li>Per SMS erhalten Sie einen Stimmcode.</li>
                            <li>Der Code muss binnen 48 Stunden eingelöst werden.</li>
                            <li>Lösen Sie den Code direkt auf dem Profil Ihres Favoriten ein, um Ihre Stimme zu
                                werten.
                            </li>
                            <li>Es kann nur ein Code pro Handynummer angefordert werden.</li>
                            <li>Nur deutsche Handynummern können teilnehmen.</li>
                            <li>Der SMS-Empfang ist kostenlos, es entstehen durch die Abstimmung keine Kosten.</li>
                            <li>Die Handynummern dienen ausschließlich der Stimmverifizierung, sie werden <strong>nicht
                                weitergegeben oder für Werbezwecke verwendet.</strong></li>
                        </ul>

                        <h2>Ich habe keine SMS mit Abstimmcode erhalten!</h2>
                        <ul>
                            <li>Haben Sie sich eventuell vertippt? Probieren Sie noch einmal, einen Code
                                anzufordern.
                            </li>
                            <li>Die Zustellung der SMS kann manchmal etwas dauern, beispielsweise wenn der
                                Handyempfang schlecht ist. Warten Sie ein wenig ab, meistens erfolgt die Zustellung
                                der SMS binnen 24 Stunden. Auf die Zustellung durch die Mobilfunkprovider können wir
                                leider keinen Einfluss nehmen.
                            </li>
                            <li>Um Ihre Daten zu schützen, können wir keine einzelnen Handynummern zurückverfolgen
                                und bestimmten Codes zuordnen. Eine erneute Versendung des Codes ist daher nicht
                                möglich.
                            </li>
                        </ul>

                        <h2>Was geschieht mit den eingegebenen Handynummern?</h2>
                        <ul>
                            <li>Die Angabe der Handynummer dient ausschließlich der Verifizierung der Stimme.</li>
                            <li>Handynummern, die für die Abstimmung verwendet wurden, werden zu keinem Zeitpunkt
                                für eine weitere Nutzung außer für den Versand des Abstimmcodes per SMS verwendet.
                                Eine Weitergabe der Daten oder werbliche Nutzung erfolgt nicht.
                            </li>
                            <li>Die Nummern werden mehrfach verschlüsselt gespeichert und 45 Tage nach Beendigung
                                des Wettbewerbs vollumfänglich gelöscht.
                            </li>
                        </ul>

                        <h2>Wenn ich auf den Button klicke, passiert nichts!</h2>
                        <ul>
                            <li>Möglicherweise ist dies auf eine veraltete Browserversion zurückzuführen. Bitte
                                achten Sie darauf immer die aktuellen Updates Ihres Browsers zu installieren.
                                Insbesondere der Internet Explorer wird von uns nicht mehr unterstützt, da dieser
                                nicht mehr mit den neuesten Technologien kompatibel ist. Wir empfehlen eine moderne
                                Alternative wie Firefox oder Google Chrome.
                            </li>
                            <li>Ein Ad Blocker kann das Laden einiger Elemente der Website verhindern. Sie können
                                Ihren Ad Blocker für die Abstimmung kurz deaktivieren oder vollständig für unsere
                                Website deaktivieren. Dann sollte die Abstimmung problemlos funktionieren.
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Modal modalClosed={this.closeModalHandler} show={this.state.showModal}>{this.state.unsuccessfulMessage}</Modal>
            </React.Fragment>
        );
    }
}

export default SMSRequest;
